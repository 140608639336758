import Api from './API'
import {getCookies} from '@/utils/cookies'
// import { get } from 'http'
// const crypto = require('crypto')

function AdminModel () {
  if (!(this instanceof AdminModel)) {
    return new AdminModel()
  }
}

/* ***** *********** ************* **************** ******************* ****************** ****
*
*                                             HRM material Api
*
***** *********** ************* **************** ******************* ****************** **** */
/** ***********************HRM SETTINGS START HERE*********************************/

/** ***********************CONTACT TYPE******************************** */

AdminModel.prototype.getUserList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchVal);
  data.append("shortBy", shortBy);
  data.append("sortOrder", sortOrder);
  Api.getUserList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}

AdminModel.prototype.getUserDetail = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("userDetailId", userDetailId);
  Api.getUserDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.RemoveAppUser = async function (
  userDetailId,
  isPermanentlyDelete,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("userDetailId", userDetailId);
  data.append("isPermanentlyDelete", isPermanentlyDelete);
  Api.RemoveAppUser(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetReportedShots = async function (
  start,
  length,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("start", start);
  data.append("length", length);
  Api.GetReportedShots(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.GetReportedShotsDetail = async function (
  postId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("postId", postId);
  Api.GetReportedShotsDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ignoreReportedPost = async function (
  postId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("postId", postId);
  Api.ignoreReportedPost(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.DeleteReportedPost = async function (
  postId,
  postFileVaultId,
  postUserDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("postId", postId);
  data.append("postFileVaultId", postFileVaultId);
  data.append("postUserDetailId", postUserDetailId);
  Api.DeleteReportedPost(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ReportedUserList = async function (
  start,
  length,
  searchVal,
  shortBy,
  sortOrder,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("start", start);
  data.append("length", length);
  data.append("searchVal", searchVal);
  data.append("shortBy", shortBy);
  data.append("sortOrder", sortOrder);
  Api.ReportedUserList(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ReportedUserDetail = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("userDetailId", userDetailId);
  Api.ReportedUserDetail(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ReportedUserIgnore = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("userDetailId", userDetailId);
  Api.ReportedUserIgnore(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
AdminModel.prototype.ReportedUserTempBlock = async function (
  userDetailId,
  callBackFunc,
  onError
) {
  const data = new FormData()
  data.append('authToken', getCookies('empCAuthToken'))
  data.append("userDetailId", userDetailId);
  Api.ReportedUserTempBlock(data).then(data => {
    if (data.success === 1) {
      callBackFunc(data)
    } else {
      onError(data)
    }
  })
}
export default AdminModel
