import axios from 'axios'
import store from '@/store'
import * as config from '../../../config.js'
/**
 * init class API
 * @param opt
 * @returns {AdminApi}
 * @constructor
 */

function AdminApi (opt) {
  if (!(this instanceof AdminApi)) {
    return new AdminApi(opt)
  }
  opt = opt || {}
  this.classVersion = '1.0.0'
  this.api = axios.create({
    baseURL: config.API_ROOT,
    timeout: 9000000
  })
  this.api.interceptors.request.use(request => {
    store.dispatch('SetIsApiRunning', true)
    return request
  })
  this.api.interceptors.response.use(response => {
    store.dispatch('SetIsApiRunning', false)
    return response
  },
  error => {
    store.dispatch('SetIsApiRunning', false)
    return error
  })
}
/**
 * init class API
 * @param opt
 * @returns {AdminApi}
 * @constructor
 */

AdminApi.prototype._requestAuthPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Authorization': 'Bearer 23432567654',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  })
}
AdminApi.prototype._requestDataPost = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  return this.api.post(url, data, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestDataPostFileAttachment = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType,
    responseType: 'blob'
  }
  return this.api.post(url, data, headers).then((response) => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestDataGet = function (url, contentType, data, callback) {
  var headers = {
    Accept: 'application/json',
    'Content-Type': contentType
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response.data
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}
AdminApi.prototype._requestFileDataGet = function (url, contentType, data, callback) {
  var headers = {
    responseType: 'arraybuffer'
  }
  var param = ''
  if (data !== '') {
    param = '/' + data
  }
  return this.api.get(url + param, headers).then(response => {
    if (response.data) {
      if (typeof callback === 'function') {
        callback()
      }
      return response
    } else {
      return {
        success: 0,
        message: response.problem
      }
    }
  }).catch(error => {
    return {
      success: 0,
      message: error.message
    }
  })
}

AdminApi.prototype.getUserList = async function (data, callback) {
  const requestUrl = 'api/v1/admin/user/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.getUserDetail = async function (data, callback) {
  const requestUrl = 'api/v1/admin/user/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.RemoveAppUser = async function (data, callback) {
  const requestUrl = 'api/v1/admin/user/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetReportedShots = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedPost/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.GetReportedShotsDetail = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedPost/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ignoreReportedPost = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedPost/ignore'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.DeleteReportedPost = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedPost/delete'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ReportedUserList = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedUser/list'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ReportedUserDetail = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedUser/detail'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ReportedUserIgnore = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedUser/ignore'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}
AdminApi.prototype.ReportedUserTempBlock = async function (data, callback) {
  const requestUrl = 'api/v1/admin/reportedUser/temporaryBlock'
  const contentType = 'application/x-www-form-urlencoded'
  return this._requestDataPost(requestUrl, contentType, data, callback)
}


export default AdminApi
