<template>
  <div class="px-4 popup_overlay">
    <div class="custom_dialog rounded-xl" style="width: 600px; overflow: auto; max-height: 85%;" v-if="detailObject !== null">
      <div class="relative">
        <div>
          <div class="bg-primary flex items-center justify-between p-3">
            <div class=" font-medium text-white heading-3 overFlowParaA">
              {{detailObject.userName}}
            </div>
            <div class="flex items-center">
              <div class=" text-error heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl" @click="confirmPopup = true">
                <i class="fas fa-trash"></i>
              </div>
              <div class=" text-primary heading-3 cursor-pointer bg-white px-3 py-1 rounded-xl ml-2" @click="closePopup()">
                <i class="fas fa-times"></i>
              </div>
            </div>
          </div>
          <div class="p-2" >
            <div class="justify-center grid text-center">
              <div class="justify-self-center" v-if="detailObject.userProfileUrl !== ''">
                <img class="rounded-full row_user_svg border border-gray2 border-opacity-30" :src="detailObject.userProfileUrl"  v-if="detailObject.userProfileUrl !== ''">
              </div>
              <div class="justify-self-center" v-if="detailObject.userProfileUrl === ''">
                <img class="rounded-full row_user_svg" src="@/assets/images/svg/user-color.svg" >
              </div>
              <div class="py-2">
                <div class="text-primary heading-2 font-semibold">{{detailObject.firstName }} {{detailObject.lastName }}</div>
              </div>
            </div>
            <div class="grid xl:grid-cols-2 lg:grid-cols-2">
              <div class="mb-4">
                <p class="text-gray4">Birth Date:</p>
                <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.birthDate | dateTimeWithName}}</p>
              </div>
              <div class="mb-4">
                <p class="text-gray4">Gender:</p>
                <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.gender}}</p>
              </div>
            </div>
            <div class="mb-4">
              <p class="text-gray4">Email:</p>
              <p class="font-semibold heading-5 text-text2 pl-2" v-if="detailObject.emailAddress !== ''">{{detailObject.emailAddress}}</p>
              <p class="font-semibold heading-5 text-text2" v-else>-</p>
            </div>
            <div class="mb-4" v-if="detailObject.profileDescription !== ''">
              <p class="text-gray4">Description:</p>
              <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.profileDescription}}</p>
            </div>
            <div class="mb-4" v-if="detailObject.wayToContact !== ''">
              <p class="text-gray4">Way to Connect:</p>
              <p class="font-semibold heading-5 text-text2 pl-2">{{detailObject.wayToContact}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="confirmPopup" class="popup_overlay relative px-4">
      <div style="width: 500px;" class="custom_dialog rounded-xl h-min p-4">
        <div  class="mb-4 font-semibold">
          <p class="heading-4 text-text1">Are you sure you want to delete <span class="text-primary">@{{detailObject.userName}}</span> user?</p>
        </div>
        <div  class="mb-5 font-semibold">
          <p class="heading-5 text-text1 mb-4">Once deleted cannot be undone</p>
        </div>
        <div class="flex gap-2 justify-end">
          <button class="heading-5 text-white px-3 py-1 rounded bg-gray4" @click="confirmPopup = false">Cancel</button>
          <button class="heading-5 text-white px-3 py-1 rounded bg-error" @click="deleteUser()">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ADMINAPI from '@/View/Admin/api/Admin.js'
export default {
props: ['detail'],
components: {
},
data () {
  return {
    confirmPopup: false,
    detailObject: null,
  }
},
 created() {
},
mounted () {
  this.getDetail()
  document.body.style = 'overflow: hidden;'
},
watch: {},
methods: {
  getDetail () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    ADMINAPI.getUserDetail(
        this.detail.userDetailId,
        response => {
          console.log("response", response.Data)
          this.detailObject = response.Data === null ? {} : response.Data
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
  },
  deleteUser () {
    this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    ADMINAPI.RemoveAppUser(
        this.detail.userDetailId,
        false,
        response => {
          this.$root.$emit('closeDetail', true)
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
  },
  closePopup () {
    this.$root.$emit('closeDetail', false)
  },
},
beforeDestroy () {
  // this.$root.$off('closeProfileDialog')
}
}
</script>
<style scoped>
.row_user_svg {
  height: 180px;
  width: auto;
}
</style>